export const modelMetadata = {
  'falcon-40b-instruct': {
    title: 'Falcon 40B Instruct | Hallucination Index 2023',
    description: `Explore Falcon 40B Instruct's performance in the 2023 Hallucination Index, analyzing its context adherence and response accuracy across tasks.`,
  },
  'gpt-35-turbo-0613': {
    title: 'GPT-3.5 Turbo 0613 | Hallucination Index 2023',
    description: `Delve into GPT-3.5 Turbo 0613's evaluation in the 2023 Hallucination Index, focusing on its effectiveness in handling various context lengths.`,
  },
  'gpt-35-turbo-1106': {
    title: 'GPT-3.5 Turbo 1106 | Hallucination Index 2023',
    description: `Review GPT-3.5 Turbo 1106's standings in the 2023 Hallucination Index, highlighting its proficiency in context adherence and response generation.`,
  },
  'gpt-35-turbo-instruct': {
    title: 'GPT-3.5 Turbo Instruct | Hallucination Index 2023',
    description: `Assess GPT-3.5 Turbo Instruct's ranking in the 2023 Hallucination Index, emphasizing its performance across diverse datasets and tasks.`,
  },
  'gpt-4-0613': {
    title: 'GPT-4 0613 | Hallucination Index 2023',
    description: `Examine GPT-4 0613's placement in the 2023 Hallucination Index, detailing its capabilities in managing short and medium context retrieval tasks.`,
  },
  'llama-2-13b-chat': {
    title: 'LLaMA 2 13B Chat | Hallucination Index 2023',
    description: `Analyze LLaMA 2 13B Chat's results in the 2023 Hallucination Index, focusing on its context adherence and response length across various tasks.`,
  },
  'llama-2-70b-chat': {
    title: 'LLaMA 2 70B Chat | Hallucination Index 2023',
    description: `Investigate LLaMA 2 70B Chat's performance in the 2023 Hallucination Index, highlighting its strengths in context recall and information retrieval.`,
  },
  'llama-2-7b-chat': {
    title: 'LLaMA 2 7B Chat | Hallucination Index 2023',
    description: `Explore LLaMA 2 7B Chat's evaluation in the 2023 Hallucination Index, assessing its effectiveness in handling different context lengths and datasets.`,
  },
  'mistral-7b-instruct-v01': {
    title: 'Mistral 7B Instruct v0.1 | Hallucination Index 2023',
    description: `Discover Mistral 7B Instruct v0.1's performance in the 2023 Hallucination Index, focusing on its context adherence and response accuracy.`,
  },
  'mpt-7b-instruct': {
    title: 'MPT-7B Instruct | Hallucination Index 2023',
    description: `Review MPT-7B Instruct's standings in the 2023 Hallucination Index, highlighting its proficiency in managing various context lengths and tasks.`,
  },
  'zephyr-7b-beta': {
    title: 'Zephyr 7B Beta | Hallucination Index 2023',
    description: `Assess Zephyr 7B Beta's ranking in the 2023 Hallucination Index, emphasizing its performance across diverse datasets and context retrieval tasks.`,
  },
  'claude-3-5-sonnet-20240620': {
    title: 'Claude 3.5 Sonnet 2024-06-20 | Hallucination Index',
    description: `Examine Claude 3.5 Sonnet 20240620's placement in the Hallucination Index, detailing its capabilities in context adherence and response generation.`,
  },
  'claude-3-haiku-20240307': {
    title: 'Claude 3 Haiku 2024-03-07 | Hallucination Index',
    description: `Analyze Claude 3 Haiku 20240307's results in the Hallucination Index, focusing on its performance across various tasks and datasets.`,
  },
  'dbrx-instruct': {
    title: 'DBRX Instruct | Hallucination Index',
    description:
      "Investigate DBRX Instruct's performance in the Hallucination Index, highlighting its strengths in context recall and information retrieval.",
  },
  'gemini-15-pro-001': {
    title: 'Gemini 1.5 Pro 001 | Hallucination Index',
    description:
      "Explore Gemini 1.5 Pro 001's evaluation in the Hallucination Index, assessing its effectiveness in handling different context lengths and tasks.",
  },
  'gemma-2-27b-it': {
    title: 'Gemma 2 27B IT | Hallucination Index',
    description:
      "Discover Gemma 2 27B IT's performance in the Hallucination Index, focusing on its context adherence and response accuracy.",
  },
  'gemma-2-9b-it': {
    title: 'Gemma 2 9B IT | Hallucination Index',
    description:
      "Review Gemma 2 9B IT's standings in the Hallucination Index, highlighting its proficiency in managing various datasets and tasks.",
  },
  'gpt-4o-2024-08-06': {
    title: 'GPT-4o 2024-08-06 | Hallucination Index',
    description:
      "Assess GPT-4O 2024-08-06's ranking in the Hallucination Index, emphasizing its performance across diverse context retrieval tasks.",
  },
  'meta-llama-31-405b-instruct': {
    title: 'Meta LLaMA 3.1 405B Instruct | Hallucination Index',
    description:
      "Examine Meta LLaMA 3.1 405B Instruct's placement in the Hallucination Index, detailing its capabilities in context adherence and response generation.",
  },
  'meta-llama-31-70b-instruct': {
    title: 'Meta LLaMA 3.1 70B Instruct | Hallucination Index',
    description:
      "Analyze Meta LLaMA 3.1 70B Instruct's results in the Hallucination Index, focusing on its performance across various tasks and datasets.",
  },
  'meta-llama-31-8b-instruct': {
    title: 'Meta LLaMA 3.1 8B Instruct | Hallucination Index',
    description:
      "Investigate Meta LLaMA 3.1 8B Instruct's performance in the Hallucination Index, highlighting its strengths in context recall and information retrieval.",
  },
  'mistral-large-2407': {
    title: 'Mistral Large 2407 | Hallucination Index',
    description:
      "Explore Mistral Large 2407's evaluation in the Hallucination Index, assessing its effectiveness in handling different context lengths and tasks.",
  },
  'o1-mini-2024-09-12': {
    title: 'O1 Mini 2024-09-12 | Hallucination Index',
    description:
      "Discover O1 Mini 2024-09-12's performance in the Hallucination Index, focusing on its context adherence and response accuracy.",
  },
  'open-mistral-nemo-2407': {
    title: 'Open Mistral Nemo 2407 | Hallucination Index',
    description:
      "Review Open Mistral Nemo 2407's standings in the Hallucination Index, highlighting its proficiency in managing various datasets and tasks.",
  },
  'qwen15-32b-chat': {
    title: 'Qwen 1.5 32B Chat | Hallucination Index',
    description:
      "Assess Qwen 1.5 32B Chat's ranking in the Hallucination Index, emphasizing its performance across diverse context retrieval tasks.",
  },
  'qwen2-72b-instruct': {
    title: 'Qwen 2 72B Instruct | Hallucination Index',
    description:
      "Examine Qwen 2 72B Instruct's placement in the Hallucination Index, detailing its capabilities in context adherence and response generation.",
  },
  'qwen2-7b-instruct': {
    title: 'Qwen 2 7B Instruct | Hallucination Index',
    description:
      "Analyze Qwen 2 7B Instruct's results in the Hallucination Index, focusing on its performance across various tasks and datasets.",
  },
  'snowflake-arctic-instruct': {
    title: 'Snowflake Arctic Instruct | Hallucination Index',
    description:
      "Investigate Snowflake Arctic Instruct's performance in the Hallucination Index, highlighting its strengths in context recall and information retrieval.",
  },
};
