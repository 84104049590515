import React from 'react';
import { graphql } from 'gatsby';
import MarketingLayout from '@/components/common/hl-marketing-layout';
import SEO from '@/components/seo';

import { ScrollWrap } from '@/components/common/scroll-wrap';

import Prefooter from '@/components/hallucination-index-2023/prefooter';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import ExternalArrow from '@/assets/learn-more.svg';
import { modelMetadata } from '@/utils/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const ModelDetailsRow = ({ label, value }) => {
  return (
    <div className="mt-3 flex flex-wrap border-b border-black/5">
      <div className="w-1/2">
        <p className="mb-1 text-xs opacity-80 lg:text-sm">{label}</p>
      </div>
      <div className="w-1/2 text-right">
        <p className="text-xs lg:text-sm">{value}</p>
      </div>
    </div>
  );
};

export default function ModelTemplate({ data, pageContext }) {
  const {
    model,
    taskPerformance,
    taskInsights,
    datasetPerformance,
    costInsights,
  } = data;

  const currentModel = model.nodes[0];

  const currentTaskPerformance = taskPerformance.edges[0].node;
  const currentTaskInsights = taskInsights.edges;
  const currentCostInsights = costInsights.edges;

  const withRagInsight = currentTaskInsights.filter(
    (insight) => insight.node.Task === 'QA with RAG',
  )[0].node.Insight;

  const withoutRagInsight = currentTaskInsights.filter(
    (insight) => insight.node.Task === 'QA without RAG',
  )[0].node.Insight;

  const longFormInsight = currentTaskInsights.filter(
    (insight) => insight.node.Task === 'Long form text generation',
  )[0].node.Insight;

  const currentDatasetPerformance = datasetPerformance.edges[0].node;

  const { modelName, slug, underscoreSlug } = pageContext;

  const chartOptions = {
    // maintainAspectRatio: false
    indexAxis: 'y',
    background: 'red',

    responsive: true,
    borderRadius: 3,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            if ((value * 10) % 2 == 0 && index < values.length - 2)
              return value;
            else return null;
          },
        },
        scaleLabel: {
          display: true,
        },
        max: 0.9,
        border: {
          display: false,
        },
        grid: {
          display: false,
          offset: true,
        },
      },
      y: {
        scaleLabel: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        align: 'end',
        anchor: 'end',
        formatter: (value, ctx) => {
          return value;
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        align: 'center',
        text: `Model Performance ${modelName}`,
        font: {
          size: 16,
        },
      },
    },
    animation: {
      duration: 0,
    },
    transitions: {
      from: 1,
    },
  };

  const chartData = {
    labels: ['QA without RAG', 'QA with RAG', 'Long form text generation'],
    datasets: [
      {
        label: 'Long form text generation',
        name: 'Long_form_text_generation',
        backgroundColor: 'black',
        // borderColor: chartBackgroundColors,
        borderWidth: 1,
        // hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        // hoverBorderColor: 'rgba(75,192,192,1)',
        data: [
          parseFloat(currentTaskPerformance.QA_without_RAG),
          parseFloat(currentTaskPerformance.QA_with_RAG),
          parseFloat(currentTaskPerformance.Long_form_text_generation),
        ],
      },
    ],
  };

  const modelInfo = [
    {
      name: 'QA without RAG',
      insight: withoutRagInsight,
      datasets: [
        {
          name: 'Truthful QA',
          performance: currentDatasetPerformance.Truthful_QA,
        },
        {
          name: 'Trivia QA',
          performance: currentDatasetPerformance.Trivia_QA,
        },
      ],
    },
    {
      name: 'QA with RAG',
      insight: withRagInsight,
      datasets: [
        {
          name: 'MS Marco',
          performance: currentDatasetPerformance.MS_Marco,
        },
        {
          name: 'Hotpot QA',
          performance: currentDatasetPerformance.Hotpot_QA,
        },
        {
          name: 'Drop',
          performance: currentDatasetPerformance.Drop,
        },
        {
          name: 'Narrative QA',
          performance: currentDatasetPerformance.Narrative_QA,
        },
      ],
    },
    {
      name: 'Long form text generation',
      insight: longFormInsight,
      datasets: [
        {
          name: 'Open Assistant',
          performance: currentDatasetPerformance.Open_Assistant,
        },
      ],
    },
  ];

  return (
    <MarketingLayout year={2023}>
      <div className="flex flex-col justify-end pb-10 pt-14 sm:pb-12 md:pb-16 lg:py-20">
        <div className="mx-auto h-full w-full max-w-[1280px] pt-10 sm:pt-12 md:pt-16 lg:pt-20">
          <div className="flex h-full flex-wrap items-end justify-between">
            <div className="w-auto px-4">
              <p className="font-serif text-[18px] font-normal leading-none md:text-[24px] lg:text-[28px] xl:text-[32px]">
                Model Insights
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-[1280px] px-4">
        <div className="border-t border-hi-3"></div>
      </div>

      <section className="py-10 sm:py-12 md:py-16 xl:py-20">
        <div className="mx-auto h-full max-w-[1280px] px-4">
          <div className="-mx-4 flex flex-wrap">
            <div className="flex w-full flex-col justify-between px-4 lg:w-1/2 xl:w-7/12">
              <div className="mb-4">
                <h1 className="text-[32px] font-normal leading-none sm:text-[40px] md:text-[48px] lg:text-[56px] xl:text-[64px]">
                  {modelName}
                </h1>
              </div>
              <div className="mb-6 lg:mb-0">
                {/* <p className="tracking-widest text-xs mb-4 uppercase">
                      About
                    </p> */}
                <p className="leading-loose">{currentModel.About_the_model}</p>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="rounded-lg border border-hi-3 p-4 lg:p-5">
                <div className="flex flex-wrap">
                  <div className="w-full bg-hi-60 px-6 py-5 text-center text-white lg:px-9 lg:py-8">
                    <p className="mb-6 text-sm font-semibold uppercase tracking-[4px]">
                      Model
                    </p>
                    <div className="text-[20px] leading-none md:text-[22px] lg:text-[24px] xl:text-[28px]">
                      {modelName}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="mt-6 w-full">
                    <p className="mb-4 text-center text-sm font-semibold uppercase tracking-[4px]">
                      Details
                    </p>
                  </div>
                  <div className="w-full">
                    <ModelDetailsRow
                      label="Developer"
                      value={currentModel.Developer}
                    />
                    <ModelDetailsRow
                      label="License"
                      value={currentModel.License}
                    />
                    <ModelDetailsRow
                      label="Model parameters"
                      value={currentModel.Model_parameters}
                    />
                    <ModelDetailsRow
                      label="Pretraining tokens"
                      value={currentModel.Pretraining_tokens}
                    />
                    <ModelDetailsRow
                      label="Release date"
                      value={currentModel.Release_date}
                    />
                    {/* <ModelDetailsRow
                        label="Suitable for QA with RAG"
                        value={currentModel.Suitable_for_QA_with_RAG_}
                      />
                      <ModelDetailsRow
                        label="Suitable for QA without RAG"
                        value={currentModel.Suitable_for_QA_without_RAG_}
                        />
                      <ModelDetailsRow
                        label="Suitable for long form text gen"
                        value={currentModel.Suitable_for_long_form_text_gen_}
                      /> */}
                    <ModelDetailsRow
                      label="Supported context length"
                      value={currentModel.Supported_context_length}
                    />
                    <ModelDetailsRow
                      label="Price for prompt tokens*"
                      value={`$${currentModel._xMxpromptxtokens}/Million tokens`}
                    />
                    <ModelDetailsRow
                      label="Price for response tokens*"
                      value={`$${currentModel._xMxresponsextokens}/Million tokens`}
                    />
                    <p className="mt-3 text-right text-[10px] opacity-50"></p>
                    <div className="mt-3 flex flex-wrap items-center">
                      <div className="w-1/2">
                        {/* <p className="text-sm lg:text-base mb-1">
                          <a
                            href="#"
                            className="text-hi-100 group font-medium flex items-center"
                          >
                            Learn More
                            <div className="w-5 h-5 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 duration-100">
                              <ExternalArrow />
                            </div>
                          </a>
                        </p> */}
                      </div>
                      <div className="w-1/2 text-right">
                        <p className="text-xs opacity-60">
                          *Note: Data based on 11/14/2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mx-auto max-w-[1280px] px-4">
        <div className="border-t border-hi-3"></div>
      </div>
      <section className="py-10 sm:py-12 md:py-16 xl:py-20">
        <div className="mx-auto h-full max-w-[1280px] px-4">
          <div className="-mx-4 flex flex-wrap space-y-6 lg:space-y-0">
            <div className="w-full px-4 lg:w-4/12">
              <div className="sticky top-24 space-y-6">
                <h3 className="font-serif text-[20px] font-normal md:text-[24px] lg:max-w-xs lg:text-[28px]">
                  Model Performance Across Task-Types
                </h3>
                <p className="max-w-xs">
                  Here's how {modelName} performed across all three task types
                </p>
              </div>
            </div>
            <div className="w-full px-4 lg:w-8/12">
              {/* <Bar data={chartData} options={chartOptions} /> */}

              <div className="border border-hi-3 px-5 py-4 lg:px-7 lg:py-6">
                <div className="pl divide-y-[1px]">
                  <div className="flex items-center justify-start py-2">
                    <div className="text- w-[160px] text-sm text-xs opacity-60">
                      Metric
                    </div>
                    {/* <div className='w-1/3 text-sm'>{item[currentData]}</div> */}
                    <div className="w-auto grow text-right text-sm text-xs opacity-60">
                      ChainPoll Score
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-start py-4">
                    <div className="w-full text-sm lg:w-[160px]">
                      QA without RAG
                    </div>

                    <div className="mt-2 flex w-full grow items-center text-sm lg:mt-0 lg:w-auto">
                      <div className="relative h-8 w-full md:h-10 lg:h-16">
                        <div
                          className="relative h-8 bg-hi-60 transition-all delay-100 duration-500 ease-out md:h-10 lg:h-16"
                          style={{
                            width: `${
                              parseFloat(
                                currentTaskPerformance['QA_without_RAG'],
                              ) * 100
                            }%`,
                            opacity: parseFloat(
                              currentTaskPerformance['QA_without_RAG'],
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="font-normal">
                        {parseFloat(
                          currentTaskPerformance['QA_without_RAG'],
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap items-center justify-start py-4">
                    <div className="w-full text-sm lg:w-[160px]">
                      QA with RAG
                    </div>

                    <div className="mt-2 flex w-full grow items-center text-sm lg:mt-0 lg:w-auto">
                      <div className="relative h-8 w-full md:h-10 lg:h-16">
                        <div
                          className="relative h-8 bg-hi-60 transition-all delay-100 duration-500 ease-out md:h-10 lg:h-16"
                          style={{
                            width: `${
                              parseFloat(
                                currentTaskPerformance['QA_with_RAG'],
                              ) * 100
                            }%`,
                            opacity: parseFloat(
                              currentTaskPerformance['QA_with_RAG'],
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="font-normal">
                        {parseFloat(
                          currentTaskPerformance['QA_with_RAG'],
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-start py-4">
                    <div className="w-full text-sm lg:w-[160px]">
                      Long form text generation
                    </div>

                    <div className="mt-2 flex w-full grow items-center text-sm lg:mt-0 lg:w-auto">
                      <div className="relative h-8 w-full md:h-10 lg:h-16">
                        <div
                          className="relative h-8 bg-hi-60 transition-all delay-100 duration-500 ease-out md:h-10 lg:h-16"
                          style={{
                            width: `${
                              parseFloat(
                                currentTaskPerformance[
                                  'Long_form_text_generation'
                                ],
                              ) * 100
                            }%`,
                            opacity: parseFloat(
                              currentTaskPerformance[
                                'Long_form_text_generation'
                              ],
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="font-normal">
                        {parseFloat(
                          currentTaskPerformance['Long_form_text_generation'],
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mx-auto max-w-[1280px] px-4">
        <div className="border-t border-hi-3"></div>
      </div>
      <section className="py-10 sm:py-12 md:py-16 xl:py-20">
        <div className="mx-auto h-full max-w-[1280px] space-y-6 px-4">
          <h3 className="font-serif text-[20px] font-normal md:text-[24px] lg:text-[28px]">
            Model Info Across Task-Types
          </h3>
          <p>
            Digging deeper, here’s a look how {modelName} performed across
            specific datasets
          </p>

          <ScrollWrap>
            <table className="w-full table-fixed border-collapse border border-hi-3 bg-hi-2 text-left text-sm lg:text-base">
              <tr>
                <th className="border-b border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Tasks
                </th>
                <th className="border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Insights
                </th>
                <th className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Dataset Name
                </th>
                <th className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Dataset Performance
                </th>
              </tr>

              <tr>
                <td
                  className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6"
                  rowSpan={2}
                >
                  QA without RAG
                </td>
                <td
                  className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6"
                  rowSpan={2}
                >
                  {withoutRagInsight}
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Truthful QA
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Truthful_QA}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${
                            currentDatasetPerformance.Truthful_QA * 100
                          }%`,
                          opacity: currentDatasetPerformance.Truthful_QA,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Trivia QA
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Trivia_QA}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${
                            currentDatasetPerformance.Trivia_QA * 100
                          }%`,
                          opacity: currentDatasetPerformance.Trivia_QA,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6"
                  rowSpan={4}
                >
                  QA with RAG
                </td>
                <td
                  className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6"
                  rowSpan={4}
                >
                  {withRagInsight}
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  MS Marco
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.MS_Marco}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${currentDatasetPerformance.MS_Marco * 100}%`,
                          opacity: currentDatasetPerformance.MS_Marco,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Hotpot QA
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Hotpot_QA}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${
                            currentDatasetPerformance.Hotpot_QA * 100
                          }%`,
                          opacity: currentDatasetPerformance.Hotpot_QA,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Drop
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Drop}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${currentDatasetPerformance.Drop * 100}%`,
                          opacity: currentDatasetPerformance.Drop,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Narrative QA
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Narrative_QA}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${
                            currentDatasetPerformance.Narrative_QA * 100
                          }%`,
                          opacity: currentDatasetPerformance.Narrative_QA,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 font-semibold md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Long form text generation{' '}
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  {longFormInsight}
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  Open Assistant
                </td>
                <td className="bg-grey-25 border-b border-l border-hi-3 px-4 py-3.5 md:px-5 md:py-4 lg:px-7 lg:py-6">
                  {/* {currentDatasetPerformance.Open_Assistant} */}
                  <div className="-mx-2 flex items-center">
                    <div className="w-16 px-2">
                      <div className="w-auto whitespace-nowrap">
                        {currentDatasetPerformance.Open_Assistant}
                      </div>
                    </div>
                    <div className="w-full px-2">
                      <div
                        className="h-5 bg-hi-60"
                        style={{
                          width: `${
                            currentDatasetPerformance.Open_Assistant * 100
                          }%`,
                          opacity: currentDatasetPerformance.Open_Assistant,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="h-4"></div>
          </ScrollWrap>

          <div className="mt-10">
            <div className="mt-10 space-y-2 border border-hi-3 bg-hi-2 p-3 lg:space-y-4 lg:p-5">
              <p className="font-serif text-[16px] md:text-[18px] lg:text-[20px]">
                💰 Cost insights
              </p>
              <p className="text-sm opacity-70 lg:text-base">
                {currentCostInsights[0].node.Insight}
              </p>
            </div>
          </div>
        </div>
      </section>

      <Prefooter />

      {/* <div className="py-10">{JSON.stringify(currentModel, null, 2)}</div>
      <div className="py-10">
        <p>currentTaskPerformance</p>
        <hr />
        {JSON.stringify(currentTaskPerformance, null, 2)}
      </div>
      <div className="py-10">
        <p>currentTaskInsights</p>
        <hr />
        {JSON.stringify(currentTaskInsights, null, 2)}
      </div>
      <div className="py-10">
        <p>currentDatasetPerformance</p>
        <hr />
        {JSON.stringify(currentDatasetPerformance, null, 2)}
      </div>
      <div className="py-10">
        <p>currentCostInsights</p>
        <hr />
        {JSON.stringify(currentCostInsights, null, 2)}
      </div> */}
    </MarketingLayout>
  );
}

export const query = graphql`
  query ModelTemplateQuery($modelName: String!) {
    model: allModelsCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        id
        Model
        About_the_model
        Developer
        License
        Model_parameters
        Pretraining_tokens
        Release_date
        Suitable_for_QA_with_RAG_
        Suitable_for_QA_without_RAG_
        Suitable_for_long_form_text_gen_
        Supported_context_length
        _xMxpromptxtokens
        _xMxresponsextokens
      }
    }
    taskPerformance: allTaskperformanceCsv(
      filter: { Model: { eq: $modelName } }
    ) {
      edges {
        node {
          Model
          Long_form_text_generation
          QA_with_RAG
          QA_without_RAG
        }
      }
    }

    taskInsights: allTaskinsightCsv(filter: { Model: { eq: $modelName } }) {
      edges {
        node {
          Insight
          Model
          Task
        }
      }
    }
    costInsights: allCostinsightCsv(filter: { Model: { eq: $modelName } }) {
      edges {
        node {
          Insight
          Model
        }
      }
    }

    datasetPerformance: allDatasetperformanceCsv(
      filter: { Model: { eq: $modelName } }
    ) {
      edges {
        node {
          Drop
          Hotpot_QA
          MS_Marco
          Model
          Narrative_QA
          Open_Assistant
          Trivia_QA
          Truthful_QA
        }
      }
    }
  }
`;

export const Head = (props) => {
  const slug = props?.pageContext?.slug;

  const metaTags = modelMetadata?.[slug];
  if (!metaTags) return null;

  return (
    <>
      <SEO title={metaTags.title} description={metaTags.description} />
    </>
  );
};
